// @flow

import * as React from 'react'
import Icon from '../Icon'
import { NOTIFICATION_TYPES } from '../../constants'
import { Container, IconContainer } from './style'

type Props = {
  children: React.Node,
  type: $Values<typeof NOTIFICATION_TYPES>,
}

const Notification = ({ children, type }: Props) => (
  <Container type={type}>
    <IconContainer>
      {type === NOTIFICATION_TYPES.ERROR ? <Icon type="hazard" /> : <Icon type="success" />}
    </IconContainer>
    {children}
  </Container>
)

export default Notification
