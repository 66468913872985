// @flow

import { css } from '@emotion/core'

export const sizes = {
  desktopHD: '1440px',
  desktop: '992px',
  tablet: '768px',
  phone: '376px',
}

export const media = {
  desktopHD: (...args: any) => css`
    @media (min-width: ${sizes.desktopHD}) {
      ${css(...args)};
    }
  `,
  desktop: (...args: any) => css`
    @media (min-width: ${sizes.desktop}) {
      ${css(...args)};
    }
  `,
  tablet: (...args: any) => css`
    @media (min-width: ${sizes.tablet}) {
      ${css(...args)};
    }
  `,
  phone: (...args: any) => css`
    @media (min-width: ${sizes.phone}) {
      ${css(...args)};
    }
  `,
}
