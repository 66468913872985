// @flow
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import anime from 'animejs'
import ReactGA from 'react-ga'
import Waypoint from 'react-waypoint'
import Pulse from 'react-reveal/Pulse'
import Zoom from 'react-reveal/Zoom'
// import Flip from 'react-reveal/Fade'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Icon from '../components/Icon'
import ContactForm from '../components/ContactForm'
import { type FluidWithWebp } from '../types'
import { Header, Biography, Clients, GetInTouch, ServicesList } from '../styles/pages/index'
import VideoJPG from '../styles/pages/assets/video.jpg'
import VideoMP4 from '../styles/pages/assets/video.mp4'
// import VideoOGV from '../styles/pages/assets/video.ogv'
import VideoWEBM from '../styles/pages/assets/video.webm'

type Props = {
  data: {
    javaScriptInternetMan: {
      image: FluidWithWebp,
    },
    headingImage: {
      image: FluidWithWebp,
    },
    logoGoogle: {
      image: FluidWithWebp,
    },
    logoDisney: {
      image: FluidWithWebp,
    },
    logoPrudential: {
      image: FluidWithWebp,
    },
    logoAtt: {
      image: FluidWithWebp,
    },
    logoMicrosoft: {
      image: FluidWithWebp,
    },
    logoNiantic: {
      image: FluidWithWebp,
    },
    logoRiot: {
      image: FluidWithWebp,
    },
    logoSamsung: {
      image: FluidWithWebp,
    },
    logo20thCenturyFox: {
      image: FluidWithWebp,
    },
    logoKaplan: {
      image: FluidWithWebp,
    },
    logoVW: {
      image: FluidWithWebp,
    },
    logoKlout: {
      image: FluidWithWebp,
    },
  },
}

type State = {}

const services = [
  'Engineering Prototypes',
  'Minimum Viable Products',
  'Tech Stack Auditing',
  'Temporary CTO Services',
  'Web Applications',
  'Native Applications',
  '508 Compliance / GDPR / Accessibility',
  'Landing Pages',
  'Microsites',
  'API Design and Implementation',
  'Continuous Integration / Continuous Delivery',
  'Architecture',
  'DevOps',
  'Serverless Deployments',
]
const technologies = [
  'MobX',
  'REST',
  'Typescript',
  'GSAP',
  'Three',
  'React Router',
  'Hapi',
  'GraphQL',
  'Apollo',
  'Redux',
  'Serverless Framework',
  'Netlify',
  'Amazon Web Services',
  'Fork and Knife',
  'Air Conditioning',
  'Yes this was just for SEO',
  'Webpack',
  'Rollup',
  'Babel',
  'DynamoDB',
  'S3',
  'Lambda',
  'EC2',
  'Contentful',
  'Mongodb',
  'SQL',
  'React',
  'React Native',
  'Gatsby',
  'Styled Components',
  'ESLint',
  'Prettier',
  'Stylelint',
  'Jest',
  'Lerna',
  'Yarn',
  'Express',
  'Koa',
  'Mocha',
  'Chai',
  'NodeJS',
  'Lodash',
  'Flow',
  'Husky',
  'Sinon',
  'Bunyan',
  'Boom',
  'Joi',
]

class IndexPage extends React.Component<Props, State> {
  componentDidMount() {
    anime
      .timeline({ loop: true, delay: 3000 })
      .add({
        targets: '.header-title .header-letter',
        opacity: [0, 1],
        easing: 'easeInOutQuad',
        duration: 2250,
        delay: (el, i) => 150 * (i + 1),
      })
      .add({
        targets: '.header-title',
        opacity: 0,
        duration: 1000,
        easing: 'easeOutExpo',
        delay: 5000,
      })
  }

  render() {
    const { data } = this.props

    return (
      <Layout>
        <SEO
          keywords={[
            'charles dudley',
            'austin',
            'freelance',
            'full stack',
            'software engineer',
            'react',
            'react native',
            'graphql',
            'serverless',
            'aws',
            'node',
            'front end',
            'back end',
          ]}
        />
        <Header>
          <Header.BackgroundContainer>
            <video loop muted autoPlay poster={VideoJPG} playsInline>
              <source src={VideoMP4} type="video/mp4" />
              <source src={VideoWEBM} type="video/webm" />
              {/* <source src={VideoOGV} type="video/ogg" /> */}
            </video>
          </Header.BackgroundContainer>
          <Header.Top>
            <Header.Top.Name>Charles Dudley</Header.Top.Name>
            <Header.Top.Icons>
              <Header.Top.Icon
                href="https://www.github.com/charlesbdudley"
                target="_blank"
                rel="noopener"
                onClick={() => {
                  ReactGA.event({
                    category: 'Outbound Link',
                    action: 'Click',
                    label: `https://www.github.com/charlesbdudley`,
                  })
                }}
              >
                <Icon type="github" />
                <span>Charles Dudley Github</span>
              </Header.Top.Icon>
              <Header.Top.Icon
                href="https://www.linkedin.com/in/charlesdudley"
                target="_blank"
                rel="noopener"
                onClick={() => {
                  ReactGA.event({
                    category: 'Outbound Link',
                    action: 'Click',
                    label: `https://www.linkedin.com/in/charlesdudley`,
                  })
                }}
              >
                <Icon type="linked-in" />
                <span>Charles Dudley LinkedIn</span>
              </Header.Top.Icon>
            </Header.Top.Icons>
          </Header.Top>
          {/* <Header.Contents> */}
          {/* <Header.Title className="header-title">
              {'Hello!'.split('').map(letter => (
                <span className="header-letter" key={shortid()}>
                  {letter}
                </span>
              ))}
            </Header.Title>
            <Header.Image>
              <Img fluid={data.headingImage.image.fluid} alt="Charles Dudley Headshot" />
            </Header.Image> */}
          {/* </Header.Contents> */}
          {/* <Header.Tagline> */}
          {/* <span></span>harles */}
          {/* JavsScript */}
          {/* </Header.Tagline> */}
          <Header.Title>
            <Zoom top>
              <Img fluid={data.javaScriptInternetMan.image.fluid} alt="Javascript Internet Man" />
            </Zoom>
          </Header.Title>
        </Header>
        <Biography>
          <Biography.Content>
            <Waypoint
              onEnter={() => {
                ReactGA.event({
                  category: 'View',
                  action: 'Biography',
                })
              }}
            />
            <p>
              <Biography.Content.Regular>
                <span role="img" aria-label="hello emoji">
                  🙋🏻‍♂️&nbsp;
                </span>
                Hello! I&rsquo;m a <b>freelance, fullstack JavaScript software engineer</b> in
                Austin, TX specializing in{' '}
              </Biography.Content.Regular>
              <Biography.Content.Highlight rotate={-2} skew={-10}>
                <span>React,</span>
              </Biography.Content.Highlight>{' '}
              <Biography.Content.Highlight rotate={-1} skew={-5}>
                <span>React Native,</span>
              </Biography.Content.Highlight>{' '}
              <Biography.Content.Highlight rotate={-2} skew={1}>
                <span>Node,</span>
              </Biography.Content.Highlight>{' '}
              <Biography.Content.Highlight rotate={-3} skew={-5}>
                <span>GraphQL,</span>
              </Biography.Content.Highlight>{' '}
              <Biography.Content.Highlight rotate={-2} skew={3}>
                <span>Serverless,</span>
              </Biography.Content.Highlight>{' '}
              <Biography.Content.Highlight rotate={-1} skew={4}>
                <span>DevOps,</span>
              </Biography.Content.Highlight>{' '}
              <Biography.Content.Regular>&amp; all things</Biography.Content.Regular>{' '}
              <Biography.Content.Highlight rotate={2} skew={5}>
                <span>AWS.</span>
              </Biography.Content.Highlight>{' '}
            </p>
            <p>
              <Biography.Content.Regular>
                I&rsquo;ve been lovingly building the internets for 17 years and have had the
                privilege of working with these fine folks:
              </Biography.Content.Regular>
            </p>
            <Clients>
              {/* <Clients.Title>Select Clients</Clients.Title> */}
              <Waypoint
                onEnter={() => {
                  ReactGA.event({
                    category: 'View',
                    action: 'Clients',
                  })
                }}
              />
              <Clients.Contents>
                <Pulse top>
                  <Clients.Client>
                    <Img fluid={data.logoGoogle.image.fluid} alt="Google Logo" />
                  </Clients.Client>
                  <Clients.Client>
                    <Img fluid={data.logoDisney.image.fluid} alt="Disney Logo" />
                  </Clients.Client>
                  <Clients.Client>
                    <Img fluid={data.logoPrudential.image.fluid} alt="Prudential Logo" />
                  </Clients.Client>
                  <Clients.Client>
                    <Img fluid={data.logoAtt.image.fluid} alt="ATT Logo" />
                  </Clients.Client>
                  <Clients.Client>
                    <Img fluid={data.logoMicrosoft.image.fluid} alt="Microsoft Logo" />
                  </Clients.Client>
                  <Clients.Client>
                    <Img fluid={data.logoNiantic.image.fluid} alt="Niantic Logo" />
                  </Clients.Client>
                  <Clients.Client>
                    <Img fluid={data.logoSamsung.image.fluid} alt="Samsung Logo" />
                  </Clients.Client>
                  <Clients.Client>
                    <Img fluid={data.logoRiot.image.fluid} alt="Riot Games Logo" />
                  </Clients.Client>
                  <Clients.Client>
                    <Img fluid={data.logo20thCenturyFox.image.fluid} alt="20th Century Fox Logo" />
                  </Clients.Client>
                  <Clients.Client>
                    <Img fluid={data.logoKaplan.image.fluid} alt="Kaplan Logo" />
                  </Clients.Client>
                  <Clients.Client>
                    <Img fluid={data.logoVW.image.fluid} alt="Volkswagen Logo" />
                  </Clients.Client>
                  <Clients.Client>
                    <Img fluid={data.logoKlout.image.fluid} alt="Klout Logo" />
                  </Clients.Client>
                </Pulse>
              </Clients.Contents>
            </Clients>

            <p>
              <span role="img" aria-label="guy at computer emoji">
                👨🏻‍💻&nbsp;
              </span>
              Here&rsquo;s a list of things I can help you with:
            </p>
            <Pulse cascade>
              <ServicesList>
                {services.map(service => (
                  <li key={service}>
                    <i>
                      <Icon type="bolt" />
                    </i>
                    <span>{service}</span>
                  </li>
                ))}
              </ServicesList>
            </Pulse>
            <p>
              <span role="img" aria-label="nerd emoji">
                🤓&nbsp;
              </span>
              If you&rsquo;re real nerdy, here&rsquo;s a list of technologies I use frequently:
            </p>
            <Pulse cascade>
              <ServicesList>
                {technologies.map(technology => (
                  <li key={technology}>
                    <span role="img" aria-label="nerd emoji">
                      🤓&nbsp;
                    </span>
                    {technology}
                  </li>
                ))}
              </ServicesList>
            </Pulse>
            <p>
              Check out my{' '}
              <a
                href="https://www.linkedin.com/in/charlesdudley"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: 'Outbound Link',
                    action: 'Click',
                    label: `https://www.linkedin.com/in/charlesdudley`,
                  })
                }}
              >
                LinkedIn
              </a>{' '}
              and{' '}
              <a
                href="https://www.github.com/charlesbdudley"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: 'Outbound Link',
                    action: 'Click',
                    label: `https://www.github.com/charlesbdudley`,
                  })
                }}
              >
                GitHub
              </a>{' '}
              profiles or fill out the form below if you&rsquo;d like to chat.
            </p>
            {/* <p>
              <a
                href="#get-in-touch"
                onClick={() => {
                  ReactGA.event({
                    category: 'Internal Link',
                    action: 'Click',
                    label: `Get in touch`,
                  })
                }}
              >
                Get in touch
              </a>
            </p> */}
          </Biography.Content>
        </Biography>
        {/* <Clients>
          <Clients.Title>Select Clients</Clients.Title>
          <Waypoint
            onEnter={() => {
              ReactGA.event({
                category: 'View',
                action: 'Clients',
              })
            }}
          />
          <Clients.Contents>
            <Clients.Client>
              <Img fluid={data.logoGoogle.image.fluid} alt="Google Logo" />
            </Clients.Client>
            <Clients.Client>
              <Img fluid={data.logoDisney.image.fluid} alt="Disney Logo" />
            </Clients.Client>
            <Clients.Client>
              <Img fluid={data.logoPrudential.image.fluid} alt="Prudential Logo" />
            </Clients.Client>
            <Clients.Client>
              <Img fluid={data.logoAtt.image.fluid} alt="ATT Logo" />
            </Clients.Client>
            <Clients.Client>
              <Img fluid={data.logoMicrosoft.image.fluid} alt="Microfost Logo" />
            </Clients.Client>
            <Clients.Client>
              <Img fluid={data.logoNiantic.image.fluid} alt="Niantic Logo" />
            </Clients.Client>
            <Clients.Client>
              <Img fluid={data.logoRiot.image.fluid} alt="Riot Games Logo" />
            </Clients.Client>
            <Clients.Client>
              <Img fluid={data.logoSamsung.image.fluid} alt="Samsung Logo" />
            </Clients.Client>
            <Clients.Client>
              <Img fluid={data.logo20thCenturyFox.image.fluid} alt="20th Century Fox Logo" />
            </Clients.Client>
            <Clients.Client>
              <Img fluid={data.logoKaplan.image.fluid} alt="Kaplan Logo" />
            </Clients.Client>
            <Clients.Client>
              <Img fluid={data.logoVW.image.fluid} alt="Volkswagen Logo" />
            </Clients.Client>
            <Clients.Client>
              <Img fluid={data.logoKlout.image.fluid} alt="Klout Logo" />
            </Clients.Client>
          </Clients.Contents>
        </Clients> */}
        <GetInTouch id="get-in-touch">
          <Waypoint
            onEnter={() => {
              ReactGA.event({
                category: 'View',
                action: 'Get in Touch',
              })
            }}
          />
          <GetInTouch.Contents>
            <GetInTouch.Title>Let&rsquo;s work together.</GetInTouch.Title>
            <ContactForm />
          </GetInTouch.Contents>
        </GetInTouch>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query HomePageQuery {
    javaScriptInternetMan: file(
      relativePath: { eq: "styles/pages/assets/javascript-internet-man.png" }
    ) {
      image: childImageSharp {
        fluid(maxWidth: 500, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    headingImage: file(relativePath: { eq: "styles/pages/assets/charles-dudley-headshot.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 877, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoGoogle: file(relativePath: { eq: "styles/pages/assets/logos/google-shadow.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 500, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoDisney: file(relativePath: { eq: "styles/pages/assets/logos/disney-shadow.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 500, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoPrudential: file(relativePath: { eq: "styles/pages/assets/logos/prudential-shadow.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 500, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoAtt: file(relativePath: { eq: "styles/pages/assets/logos/att-shadow.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 500, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoMicrosoft: file(relativePath: { eq: "styles/pages/assets/logos/microsoft-shadow.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 500, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoNiantic: file(relativePath: { eq: "styles/pages/assets/logos/niantic-shadow.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 500, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoRiot: file(relativePath: { eq: "styles/pages/assets/logos/riot-shadow.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 500, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoSamsung: file(relativePath: { eq: "styles/pages/assets/logos/samsung-shadow.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 500, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logo20thCenturyFox: file(
      relativePath: { eq: "styles/pages/assets/logos/20th-century-fox-shadow.png" }
    ) {
      image: childImageSharp {
        fluid(maxWidth: 500, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoKaplan: file(relativePath: { eq: "styles/pages/assets/logos/kaplan-shadow.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 500, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    logoVW: file(relativePath: { eq: "styles/pages/assets/logos/vw-shadow.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 500, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    logoKlout: file(relativePath: { eq: "styles/pages/assets/logos/klout-shadow.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 500, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
