import styled from '@emotion/styled'
// import { Link } from 'gatsby'
import { media } from '../dimensions'
import * as Fonts from '../fonts'
import Colors from '../colors'

export const Header = styled.div`
  background: ${Colors.pureBlack};
  position: relative;

  /* height: 280px; */

  ${media.tablet`
    /* height: 1000px; */
  `};
`

Header.BackgroundContainer = styled.div`
  /* height: 800px; */
  height: 100vh;
  overflow: hidden;

  video {
    /* position: absolute; */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (min-aspect-ratio: 16/9) {
      height: 300%;
      top: -100%;
    }

    @media (max-aspect-ratio: 16/9) {
      width: 300%;
      left: -100%;
    }

    @supports (object-fit: cover) {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

// Header.BackgroundContainer = styled.div`
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;

//   video {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;

//     @media (min-aspect-ratio: 16/9) {
//       height: 300%;
//       top: -100%;
//     }

//     @media (max-aspect-ratio: 16/9) {
//       width: 200%;
//       left: -50%;
//     }
//   }
// `

Header.Top = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Colors.white};
  padding: 24px;

  ${media.tablet`
    padding: 56px;
  `};
`

Header.Top.Name = styled.div`
  ${Fonts.Body};
  color: ${Colors.white};
  font-size: 16px;

  ${media.tablet`
    font-size: 24px;
  `}
`

Header.Top.Icons = styled.div`
  display: flex;
`

Header.Top.Icon = styled.a`
  display: block;
  width: 24px;
  height: 24px;
  color: ${Colors.white};
  margin-right: 16px;
  overflow: hidden;
  position: relative;

  &:last-of-type {
    margin-right: 0;
  }

  span {
    position: absolute;
    top: -200px;
  }
`

Header.Title = styled.div`
  position: absolute;
  bottom: 20vh;
  width: 80vw;
  left: 50%;
  margin-left: -40vw;
`

// Header.Contents = styled.div`
//   ${media.tablet`
//     max-width: 1440px;
//     margin: 0 auto;
//     display: flex;
//     align-items: center;
//   `};
// `

// Header.Image = styled.div`
//   flex: 1;
// `

// Header.Title = styled.h1`
//   flex: 1;
//   text-align: center;
//   font-weight: 400;
//   font-size: 72px;
//   color: ${Colors.white};
//   margin: 0;
//   letter-spacing: -1.77px;
//   margin-top: 24px;

//   ${media.tablet`
//     font-size: 128px;
//     order: 2;
//     margin-top: 0;
//   `};

//   ${media.desktop`
//     font-size: 156px;
//   `};

//   .header-letter {
//     display: inline-block;
//     transform-origin: 0 0;
//   }
// `

export const Biography = styled.div`
  /* background: ${Colors.blue}; */
  /* border-radius: 1vw; */
  background: rgba(214, 0, 255, 1);
  background: linear-gradient(145deg, rgba(247,166,0,1) 0%, rgba(243,29,130,1) 50%, rgba(175,0,191,1) 100%);
  position: relative;
  /* margin: 0 10vw; */
  box-sizing: border-box;
  padding: 4vw;
`

Biography.Content = styled.div`
  position: relative;
  /* top: -200px; */
  font-weight: 300;
  color: ${Colors.white};
  letter-spacing: 0;
  /* max-width: 1440px; */
  /* margin: 0 auto; */
  font-size: 24px;
  line-height: 32px;
  /* padding: 36px 24px; */
  /* padding: 24px 16px; */

  ${media.tablet`
    font-size: 40px;
    line-height: 56px;
    /* padding: 72px; */
  `}

  ${media.desktop`
    font-size: 54px;
    line-height: 70px;
    /* padding: 128px 160px; */
  `};

  p {
    margin-top: 0;
    position: relative;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    padding: 3px 6px;
    border: 2px solid ${Colors.util.fade({ color: Colors.white, amount: 0.75 })};
    border-radius: 6px;
    color: ${Colors.white};

    text-decoration: none;

    &:hover {
      background: ${Colors.util.fade({ color: Colors.white, amount: 0.75 })};
    }
  }
`

Biography.Content.Regular = styled.span`
  position: relative;
  z-index: 3;
`

Biography.Content.Highlight = styled.span`
  position: relative;
  font-weight: bold;
  padding: 0 4px;
  margin: 0 4px;
  display: inline-block;
  /* background-color: rgba(255, 182, 68, 0.5); */
  /* background: ${Colors.orange}; */
  /* background-color: rgba(0, 255, 159, 1); */
  background-color: rgba(0, 184, 255, 0.8);
  transform: ${props =>
    `scaleX(1.05) scaleY(0.9) rotate(${props.rotate}deg) skew(${props.skew}deg)`};
  
  z-index: 1;

  span {
    display: inherit;
    transform: ${props =>
      `scaleX(0.95) scaleY(1.1) rotate(${-1 * props.rotate}deg) skew(${-1 * props.skew}deg)`};
    z-index: 3;
  }
`

export const Clients = styled.div`
  position: relative;
  color: ${Colors.white};
  background: transparent;
  margin-bottom: 1em;

  /* background: ${Colors.black}; */
  /* background: ${Colors.util.fade({ color: '#00b8ff', amount: 0 })}; */
  /* padding: 72px 0; */

  ${media.tablet`
    /* padding: 128px 0; */
  `};
`

Clients.Title = styled.h3`
  font-weight: 400;
  font-size: 24px;
  color: ${Colors.lightBlue};
  letter-spacing: 0;
  text-align: center;
  line-height: 24px;
  margin-bottom: 64px;
  text-transform: uppercase;
`

Clients.Contents = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  /* background: ${Colors.util.fade({ color: Colors.lightBlack2, amount: 0.5 })}; */
  /* background: ${Colors.lightBlack2}; */
  /* border-top: 1px solid ${Colors.lightBlack2};
  border-bottom: 1px solid ${Colors.lightBlack2}; */
  grid-template-columns: 1fr 1fr;
  grid-gap: 1vw;

  ${media.tablet`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};

  ${media.desktop`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};

  ${media.desktopHD`
    /* border-left: 1px solid ${Colors.lightBlack2};
    border-right: 1px solid ${Colors.lightBlack2}; */
  `}
`

Clients.Client = styled.div`
  /* height: calc(50vw; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1vw;
  /* mix-blend-mode: multiply; */
  /* background: ${Colors.black}; */
  /* background: #00b8ff; */
  background: ${Colors.util.fade({ color: Colors.black, amount: 0.975 })};
  /* background: linear-gradient(145deg, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.05) 100%); */

  /* ${media.tablet`
    height: 25vw;
  `};

  ${media.desktop`
    height: 25vw;
  `};

  ${media.desktopHD`
    height: ${0.25 * 1440}px;
  `} */

  img { 
    /* mix-blend-mode: multiply; */
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

export const GetInTouch = styled.div`
  background: ${Colors.lightBlue};
  position: relative;
  padding: 36px 24px;

  ${media.tablet`
    padding: 72px;
  `}

  ${media.desktop`
    padding: 128px 160px;
  `};
`

GetInTouch.Contents = styled.div`
  max-width: 752px;
  margin: 0 auto;
`

GetInTouch.Title = styled.h2`
  font-weight: 300;
  color: ${Colors.lightBlack};
  letter-spacing: 0;
  margin: 0;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  margin-bottom: 24px;

  ${media.tablet`
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 48px;
  `}

  ${media.desktop`
    font-size: 54px;
    line-height: 70px;
    margin-bottom: 96px;
  `};
`

export const ServicesList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-bottom: 3em;

  li {
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: center;
    padding-right: 1em;
    line-height: 1.5em;

    i {
      display: inline-block;
      width: 24px;
      height: 24px;
      color: rgba(247, 166, 0, 1);
      line-height: 1em;
      font-style: normal;
    }
  }

  ${media.tablet`
    li {
      font-size: 24px;
      line-height: 1.5em;
    }
  `}

  ${media.desktop`
    margin-bottom: 5em;
    
    li {      
      font-size: 32px;
      line-height: 1.5em;

      i {
        width: 32px;
        height: 32px;
      }
    }
  `};
`
