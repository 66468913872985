// @flow
import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

type Props = {
  description?: string | null,
  lang?: string,
  meta?: Array<{
    name: string,
  }>,
  keywords?: Array<string>,
  title?: string,
  location?: {
    origin: string,
    pathname: string,
  },
}

const SEO = ({ description, lang = 'en', meta = [], keywords = [], title }: Props) => (
  <StaticQuery
    query={detailsQuery}
    render={data => {
      const metaDescription = description || data.site.siteMetadata.description
      return (
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={title || data.site.siteMetadata.title}
          titleTemplate={
            title ? `%s | ${data.site.siteMetadata.title}` : data.site.siteMetadata.title
          }
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: title || data.site.siteMetadata.title,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            { property: 'og:image', content: 'https://charlesdudley.com/facebook-share-image.jpg' },
            {
              property: 'og:url',
              content: 'https://charlesdudley.com',
            },
            { name: `twitter:card`, content: `summary_large_image` },
            {
              name: `twitter:image:alt`,
              content: description || data.site.siteMetadata.description,
            },
            { name: `twitter:creator`, content: data.site.siteMetadata.author },
            { name: `twitter:site`, content: data.site.siteMetadata.author },
            {
              name: `twitter:title`,
              content: `${title || data.site.siteMetadata.title} | ${data.site.siteMetadata.title}`,
            },
            {
              name: `twitter:text:title`,
              content: `${title || data.site.siteMetadata.title} | ${data.site.siteMetadata.title}`,
            },
            { name: `twitter:description`, content: metaDescription },
          ]
            .concat(
              keywords.length > 0
                ? {
                    name: `keywords`,
                    content: keywords.join(`, `),
                  }
                : [],
            )
            .concat(meta)}
        />
      )
    }}
  />
)

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  description: null,
  title: '',
  location: {},
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
