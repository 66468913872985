// @flow
import Color from 'color'

const colors = Object.freeze({
  pureBlack: '#000',
  black: '#0A0A0A',
  white: '#fff',
  lightBlue: '#DBEDF3',
  lightBlack: '#202020',
  lightBlack2: '#1f1f1f',
  green: '#7dc855',
  orange: '#FFB644',
  offWhite: '#f4f4f4',
  grey1: '#43484d',
  grey2: '#848484',
  grey3: '#3b3d40',
  grey4: '#86939e',
  grey5: '#F7F7F9',
  grey6: '#E1E8EE',
  primary: '#4A90E2',
  secondary: '#358ed7',
  backgroundGradient: 'radial-gradient(farthest-corner at 200px 70px, #39485a 0%, #1b2531 100%)',
  error: '#FF585B',
  blue: '#4A90E2',
})

type ColorT = $Values<typeof colors>

const util = {
  hover: (color: ColorT) =>
    Color(color)
      .lighten(0.1)
      .string(),

  pressed: (color: ColorT) =>
    Color(color)
      .darken(0.1)
      .string(),

  disabled: (color: ColorT) =>
    Color(color)
      .grayscale()
      .negate()
      .fade(0.5)
      .string(),

  fade: ({ color, amount }: { color: ColorT, amount: number }) =>
    Color(color)
      .fade(amount)
      .string(),

  lighten: ({ color, amount }: { color: ColorT, amount: number }) =>
    Color(color)
      .lighten(amount)
      .string(),

  darken: ({ color, amount }: { color: ColorT, amount: number }) =>
    Color(color)
      .darken(amount)
      .string(),
}

export default {
  ...colors,
  util,
}
