// @flow

import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Global } from '@emotion/core'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import ReactGA from 'react-ga'
import Icon from '../Icon'
import { type FluidWithWebp } from '../../types'
import { globalStyles, Footer, Signature, SocialIcons, MadeWithLove } from './style'

type Props = {
  children: React.Node,
}

type StaticQueryData = {
  signature: {
    image: FluidWithWebp,
  },
  background: {
    image: FluidWithWebp,
  },
}

class Layout extends React.Component<Props, *> {
  componentDidMount() {
    const { GATSBY_GOOGLE_ANALYTICS_ID } = process.env

    if (GATSBY_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(GATSBY_GOOGLE_ANALYTICS_ID)
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }

  render() {
    const { children } = this.props

    return (
      <StaticQuery
        query={signatureQuery}
        render={(data: StaticQueryData) => (
          <>
            <Global styles={globalStyles} />
            {/* This is for Google Search Console */}
            <Helmet
              meta={[
                {
                  name: 'google-site-verification',
                  content: 'sCfdJpGVNKYESwzYsROmiwXjnrzwKN9C7--r1o78G_I',
                },
              ]}
            />
            <div>
              {children}
              <Footer>
                <Signature>
                  <Img fluid={data.signature.image.fluid} alt="" />
                </Signature>
                <SocialIcons>
                  <SocialIcons.Icon
                    href="https://www.github.com/charlesbdudley"
                    target="_blank"
                    rel="noopener"
                    onClick={() => {
                      ReactGA.event({
                        category: 'Outbound Link',
                        action: 'Click',
                        label: `https://www.github.com/charlesbdudley`,
                      })
                    }}
                  >
                    <Icon type="github" />
                    <span>Charles Dudley Github</span>
                  </SocialIcons.Icon>
                  <SocialIcons.Icon
                    href="https://www.linkedin.com/in/charlesdudley"
                    target="_blank"
                    rel="noopener"
                    onClick={() => {
                      ReactGA.event({
                        category: 'Outbound Link',
                        action: 'Click',
                        label: `https://www.linkedin.com/in/charlesdudley`,
                      })
                    }}
                  >
                    <Icon type="linked-in" />
                    <span>Charles Dudley LinkedIn</span>
                  </SocialIcons.Icon>
                </SocialIcons>
                <MadeWithLove>
                  Made with <MadeWithLove.Heart>❤</MadeWithLove.Heart>in Austin, TX
                </MadeWithLove>
              </Footer>
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400"
              />
            </div>
          </>
        )}
      />
    )
  }
}

const signatureQuery = graphql`
  query signatureQuery {
    signature: file(relativePath: { eq: "components/Layout/assets/signature.png" }) {
      image: childImageSharp {
        fluid(maxWidth: 302, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default Layout
