// @flow

import styled from '@emotion/styled'
import { Container as TextInputContainer } from '../TextInput/style'
import { Container as NotificationContainer } from '../Notification/style'
import { ButtonContainer } from '../Button/style'
import { media } from '../../styles/dimensions'

export const Container = styled.form`
  ${TextInputContainer} {
    margin-bottom: 32px;
  }

  ${NotificationContainer} {
    margin-bottom: 32px;
  }
`

export const Row = styled.div`
  ${ButtonContainer},
  ${NotificationContainer} {
    width: 100%;
  }

  ${media.tablet`
      display: flex;

      ${TextInputContainer} {
        margin-right: 32px;

        &:last-of-type {
          margin-right: 0;
        }
      }
  `};
`
