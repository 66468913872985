import 'whatwg-fetch'

const BASE_URL = process.env.GATSBY_API_URL
const GENERIC_ERROR = 'Something went wrong. Please try again.'

const buildHeaders = () => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
  }

  return defaultHeaders
}

const encode = data =>
  Object.keys(data)
    // .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')

const checkStatusAndParseJSON = response =>
  response
    .json()
    .then(json => {
      if (response.status >= 200 && response.status <= 300) {
        return Promise.resolve(json)
      }

      return Promise.reject(json.message || GENERIC_ERROR)
    })
    .catch(message => {
      if (response.status >= 200 && response.status <= 300) {
        return Promise.resolve({})
      }

      return Promise.reject(message || GENERIC_ERROR)
    })

// const bail = () => Promise.reject(GENERIC_ERROR)

const post = (endpoint, data) =>
  fetch(`${BASE_URL}/${endpoint}`, {
    method: 'POST',
    body: JSON.stringify(data),
    mode: 'cors',
    // credentials: 'include',
    headers: {
      ...buildHeaders(),
    },
  }).then(checkStatusAndParseJSON)
// .catch(bail)

const newsletterSignUp = ({ email }) => post('newsletter-sign-up', { email })
const sendMessage = ({ action, payload }) =>
  fetch(action, {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode(payload),
  })
    .then(response => {
      if (response.status >= 200 && response.status <= 300) {
        return Promise.resolve()
      }

      return Promise.reject(GENERIC_ERROR)
    })
    .catch(() => Promise.reject(GENERIC_ERROR))

export default {
  newsletterSignUp,
  sendMessage,
}
