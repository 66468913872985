// @flow

import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const globalStyles = css`
  .sk-spinner.line-scale {
    height: 24px;

    > div {
      height: 24px;
      margin: 0 2px;
    }
  }
`

export const Container = styled.div`
  position: relative;
`
