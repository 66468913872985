// @flow

import * as React from 'react'
import shortid from 'shortid'
import Icon from '../Icon'
import Button from '../Button'
import {
  Container,
  InputContainer,
  Label,
  Caption,
  IconContainer,
  Error,
  TextInputElement,
  TextareaElement,
} from './style'

type Props = {
  disabled?: boolean,
  onChange: Function,
  label?: string | null,
  caption?: string | null,
  errors?: Array<string>,
  placeholder?: string | null,
  value: string,
  type?: 'input' | 'area',
  kind?: 'normal' | 'grey',
  rows?: number,
  iconLeft?: string | null,
  buttonLabel?: string,
  onButtonClick?: Function,
  loading?: boolean,
}

type State = {}

type InputChangeEvent = {
  target: {
    value: string,
  },
}

class TextInput extends React.Component<Props, State> {
  static defaultProps = {
    disabled: false,
    label: null,
    caption: null,
    placeholder: null,
    errors: [],
    type: 'input',
    kind: 'normal',
    rows: 1,
    iconLeft: null,
    buttonLabel: '',
    onButtonClick: () => {},
    loading: false,
  }

  handleChange = (event: InputChangeEvent) => {
    const { disabled, onChange } = this.props

    if (!disabled) {
      onChange(event.target.value)
    }
  }

  renderLabel = () => {
    const { label, caption, kind, errors = [], disabled } = this.props

    if (!label) {
      return null
    }

    return (
      <Label kind={kind} hasErrors={Boolean(errors.length)} disabled={disabled}>
        {label}
        {caption ? <Caption>{caption}</Caption> : null}
      </Label>
    )
  }

  renderIconLeft = () => {
    const { iconLeft, kind } = this.props

    if (!iconLeft) {
      return null
    }

    return (
      <IconContainer kind={kind}>
        <Icon type={iconLeft} />
      </IconContainer>
    )
  }

  renderButton = () => {
    const { buttonLabel, onButtonClick, loading } = this.props

    if (!buttonLabel) {
      return null
    }

    return <Button label={buttonLabel} onClick={onButtonClick} loading={loading} />
  }

  renderErrors(): React.Node | null {
    const { errors = [] } = this.props

    if (!errors.length) {
      return null
    }

    return errors.map(error => <Error key={shortid.generate()}>{error}</Error>)
  }

  render() {
    const { placeholder, disabled, value, errors = [], type, kind, rows, buttonLabel } = this.props

    const Input = type === 'input' ? TextInputElement : TextareaElement

    return (
      <Container kind={kind} hasErrors={Boolean(errors.length)} disabled={disabled}>
        {this.renderLabel()}
        <InputContainer
          kind={kind}
          hasErrors={Boolean(errors.length)}
          disabled={disabled}
          hasButton={Boolean(buttonLabel)}
        >
          {this.renderIconLeft()}
          <Input
            data-has-errors={errors.length}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={this.handleChange}
            rows={rows}
          />
          {this.renderButton()}
        </InputContainer>
        {this.renderErrors()}
      </Container>
    )
  }
}

export default TextInput
