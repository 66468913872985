// @flow

import * as React from 'react'
import ReactSpinner from 'react-spinkit'
import { Global } from '@emotion/core'
import Colors from '../../styles/colors'
import { globalStyles, Container } from './style'

const Spinner = () => (
  <>
    <Global styles={globalStyles} />
    <Container>
      <ReactSpinner name="line-scale" color={Colors.white} fadeIn="none" />
    </Container>
  </>
)

export default Spinner
