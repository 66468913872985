// @flow

import React from 'react'
import validate from 'validate.js'
import ReactGA from 'react-ga'
import Button from '../Button'
import TextInput from '../TextInput'
import Notification from '../Notification'
import API from '../../services/API'
import { Container, Row } from './style'
import { NOTIFICATION_TYPES } from '../../constants'

type Props = {}

type State = {
  isSubmitting: boolean,
  formData: {|
    firstName: string,
    lastName: string,
    company: string,
    email: string,
    message: string,
    'form-name': string,
  |},
  validationErrors: {
    firstName?: Array<string>,
    lastName?: Array<string>,
    email?: Array<string>,
    message?: Array<string>,
  },
  error: string | null,
  success: boolean,
}

const defaultFormData = {
  firstName: '',
  lastName: '',
  company: '',
  email: '',
  message: '',
  'form-name': 'contact',
}

class ContactForm extends React.Component<Props, State> {
  state = {
    isSubmitting: false,
    formData: defaultFormData,
    validationErrors: {},
    error: null,
    success: false,
  }

  validationConstraints = {
    firstName: {
      presence: { allowEmpty: false },
    },
    lastName: {
      presence: { allowEmpty: false },
    },
    email: {
      presence: { allowEmpty: false },
    },
    message: {
      presence: { allowEmpty: false },
    },
  }

  form: any = React.createRef()

  validateFields = () => {
    const { formData } = this.state
    const validationErrors = validate(formData, this.validationConstraints)

    if (validationErrors) {
      this.setState({
        validationErrors,
        error: 'Please correct the items in red and try again.',
        success: false,
      })
      return false
    }

    this.setState({ validationErrors: {}, success: false })
    return true
  }

  handleFirstNameChange = (firstName: string) => {
    const { formData } = this.state

    const updatedFormData = {
      ...formData,
      firstName,
    }

    this.setState({ formData: updatedFormData })
  }

  handleLastNameChange = (lastName: string) => {
    const { formData } = this.state

    const updatedFormData = {
      ...formData,
      lastName,
    }

    this.setState({ formData: updatedFormData })
  }

  handleCompanyChange = (company: string) => {
    const { formData } = this.state

    const updatedFormData = {
      ...formData,
      company,
    }

    this.setState({ formData: updatedFormData })
  }

  handleEmailChange = (email: string) => {
    const { formData } = this.state

    const updatedFormData = {
      ...formData,
      email,
    }

    this.setState({ formData: updatedFormData })
  }

  handleMessageChange = (message: string) => {
    const { formData } = this.state

    const updatedFormData = {
      ...formData,
      message,
    }

    this.setState({ formData: updatedFormData })
  }

  handleSubmit = (e?: { preventDefault: Function, stopPropagation: Function }) => {
    if (e && e.preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    }

    const { formData } = this.state

    ReactGA.event({
      category: 'Get In Touch',
      action: 'Submit',
    })

    if (this.validateFields()) {
      this.setState({ isSubmitting: true, error: null, success: false })

      API.sendMessage({
        action: this.form.current.action,
        payload: { ...formData, 'form-name': 'contact' },
      })
        .then(() => {
          this.setState({ isSubmitting: false, success: true, formData: defaultFormData })

          ReactGA.event({
            category: 'Get In Touch',
            action: 'Success',
          })
        })
        .catch(error => {
          this.setState({ isSubmitting: false, success: false, error })

          ReactGA.event({
            category: 'Get In Touch',
            action: 'Error',
            label: `Error: ${error}`,
          })
        })
    }
  }

  render() {
    const { isSubmitting, formData, validationErrors, error, success } = this.state

    return (
      <Container
        onSubmit={this.handleSubmit}
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        ref={this.form}
      >
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="email" />
        <input type="hidden" name="firstName" />
        <input type="hidden" name="lastName" />
        <input type="hidden" name="company" />
        <input type="hidden" name="message" />
        <Row>
          <TextInput
            label="First name"
            value={formData.firstName}
            disabled={isSubmitting}
            onChange={this.handleFirstNameChange}
            errors={validationErrors.firstName}
          />
          <TextInput
            label="Last name"
            value={formData.lastName}
            disabled={isSubmitting}
            onChange={this.handleLastNameChange}
            errors={validationErrors.lastName}
          />
        </Row>
        <Row>
          <TextInput
            label="Company"
            caption="optional"
            value={formData.company}
            disabled={isSubmitting}
            onChange={this.handleCompanyChange}
          />
          <TextInput
            label="Email"
            value={formData.email}
            disabled={isSubmitting}
            onChange={this.handleEmailChange}
            errors={validationErrors.email}
          />
        </Row>
        <Row>
          <TextInput
            type="area"
            rows={4}
            label="Message"
            value={formData.message}
            disabled={isSubmitting}
            onChange={this.handleMessageChange}
            errors={validationErrors.message}
          />
        </Row>
        <Row>
          {error ? <Notification type={NOTIFICATION_TYPES.ERROR}>{error}</Notification> : null}
          {success ? (
            <Notification type={NOTIFICATION_TYPES.SUCCESS}>Message received!</Notification>
          ) : null}
        </Row>
        <Row>
          <Button
            loading={isSubmitting}
            onClick={this.handleSubmit}
            label="🛳 Ship it"
            style={{ flex: 1 }}
          />
        </Row>
      </Container>
    )
  }
}

export default ContactForm
