import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Colors from '../../styles/colors'
// import * as Fonts from '../../styles/fonts'
import { media } from '../../styles/dimensions'

export const globalStyles = css`
  html {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    height: 100%;
    position: relative;
  }

  button {
    outline: none;
  }
`

export const Footer = styled.footer`
  background: ${Colors.black};
  padding: 24px;
  text-align: center;
`

export const Signature = styled.div`
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: 24px;
`

export const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  ${media.desktop`
    grid-area: social;
    align-self: start;
    justify-self: end;
    margin-bottom: 24px;
  `};
`

SocialIcons.Icon = styled.a`
  display: block;
  width: 24px;
  height: 24px;
  color: ${Colors.white};
  margin-right: 16px;
  overflow: hidden;
  position: relative;
  opacity: 1;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    opacity: 1;
  }

  span {
    position: absolute;
    top: -200px;
  }
`

export const MadeWithLove = styled.p`
  position: relative;
  margin: 0;
  color: ${Colors.white};
  font-size: 12px;
`

MadeWithLove.Heart = styled.span`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
`
