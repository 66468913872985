// @flow
import React from 'react'

// $FlowFixMe
const files = require.context('!svg-sprite-loader!./assets', false, /.*\.svg$/)

files.keys().forEach(files)

type Props = {
  type: string,
  width?: string,
  height?: string,
}

const Icon = ({ type, width, height }: Props) => (
  <svg className={`svg-${type}`} width={width} height={height}>
    <use href={`#${type}`} xlinkHref={`#${type}`} />
  </svg>
)

Icon.defaultProps = {
  width: '100%',
  height: '100%',
}

export default Icon
