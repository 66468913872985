// @flow

import * as React from 'react'
import Spinner from '../Spinner'
import { type ButtonTypesT, ButtonContainer, LinkContainer, Label, SpinnerContainer } from './style'

type Props = {
  onClick?: Function,
  disabled?: boolean,
  label: string,
  style?: any,
  to?: string | null,
  loading?: boolean,
  kind?: ButtonTypesT,
}

class Button extends React.Component<Props, *> {
  static defaultProps = {
    disabled: false,
    style: {},
    onClick: null,
    to: null,
    loading: false,
    kind: 'primary',
  }

  handleClick = () => {
    const { onClick, disabled, loading } = this.props

    if (!disabled && !loading && onClick) {
      onClick()
    }
  }

  render() {
    const { disabled, style, to, label, loading, kind } = this.props
    const Container = to ? LinkContainer : ButtonContainer

    return (
      <Container
        onClick={this.handleClick}
        disabled={disabled || loading}
        to={to}
        kind={kind}
        style={style}
        type="button"
      >
        <Label visible={!loading}>{label}</Label>
        {loading && (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        )}
      </Container>
    )
  }
}

export default Button
